import React from "react"

import { CustomHowWeDoIt } from "../components/how-we-do-it"
import Layout from "../components/layout"
import { PurpleHeroSection } from "../components/purple-hero-sections"
import SEO from "../components/seo"
import { OtherServices } from "./product-strategy"

const HeroSection = () => (
  <PurpleHeroSection
    titleFirst={"Product"}
    titleSecond={"Design"}
    subtitle={"Strategy leads the way"}
    content={
      "Product design is a strategy made tangible. It's the purpose-driven\n" +
      "                    unification of complex components into simple, elegant user experiences\n" +
      "                    &ndash; digital tools that draw technology and people together."
    }
    image={require("../assets/services-icon-des.svg")}
  />
)
const DifferentByDesign = () => {
  const data = [
    {
      title: "Informed by insight",
      content:
        "What will work for your audiences shouldn't be a matter of opinion. AT Touchcore, unbiased research, keen, insight, data, and analysis inform our user experience and design decisions — and this insight translates into finely polished software, mobile, and web apps.",
    },
    {
      title: "Form follows function",
      content:
        "Product strategy begins to come to life as prototypes, with testable user experiences. Making sure that your custom mobile, software, and web applications feel right, through robust testing and iteration, is baked into Touchcore's process. Risk mitigation and validation levels-up subsequent design stages, ensuring the results suit your end goals.",
    },
    {
      title: "Stunning brand experiences",
      content:
        "Color, hierarchy, typography, content — creative expression of elements such as these enable us to bring your brand to life and exceed audience expectations. There's a look, intuitive feel, and functional experience of your brand — whether long established or newly created — and Touchcore digital product design sets off with that complete package in mind.",
    },
    {
      title: "Flexible design systems",
      content:
        "Creating a design system with modular, reusable components and a library of brand and style guidelines allows creative and development teams to build faster and with greater precision. it also enables your mobile, software, and progressive web applications to evolve seamlessly as your business grows, ensuring your investment pays off.",
    },
    {
      title: "Team continuity",
      content:
        "Unlike most mobile, software, and web development consultancies that enlist specialized designers for each project phase — strategy, user experience, design — Touchcore recruits rare cross-functional professionals who adeptly do it all. Because of this, what our product designers learn during research and prototyping continues to add value as design crystalizes.",
    },
    {
      title: "Designed for the future",
      content:
        "Touchcore applies an iterative approach to digital product design so each incremental investment keeps us advancing. We respond to stakeholder feedback and confirm our course through user testing and prototyping. Each iteration focuses development towards end products that are flexible and scalable enough to meet future business needs.",
    },
  ]
  return (
    <section>
      <section className="container pt-16 pb-48 white-dots-bg bg-cover bg-no-repeat">
        <div className="text-center w-9/12 mx-auto">
          <h4 className="text-pgray leading-tight text-4xl font-semibold ">
            Different,
            <br className="md:hidden" /> by design
          </h4>
          <p className="text-justify md:text-center text-pgray2">
            Before Touchcore actually engineers a product, we always talk to our
            customers to understand their needs. Our team can analyse your
            project's functionality, business logic, software architecture, and
            future user scenarios. We'll sweat the small stuff so you can focus
            on the big picture.
          </p>
        </div>
      </section>
      <section className="container px-5 md:px-12">
        <TableLikeComponent data={data} />
        <img alt="" src={require("../assets/Image 4@2x.png")} />
      </section>
    </section>
  )
}
const HowWeDoIt = () => {
  const data = [
    {
      title: "Scope preparation",
      content:
        "In the first step of our journey into your product, we focus on your vision. This is where we get to know your idea from head-to-toe and create a comprehensive brief for the project. It allows us to gather all the research needed, from numbers on the market to competitor benchmarks or any other information necessary. This will prevent us from being blocked by a lack of information during our immersive session together.",
    },
    {
      title: "Immersive Session",
      content:
        "Bringing all the results from our research, we meet with you to craft a rock- solid value proposition and set the main assumptions to prove.Then we create the first draft of the journey your users will take through your product.These initial user stories will help evaluate the features during the next step - Product Rationale.",
    },
    {
      title: "Product Rationale",
      content:
        "At this point, we’ll have all the information we need to create the ultimate list of User Stories and features necessary to prove the main assumptions in a Minimum Viable Product(MVP) or Proof of Concept(POC).Our experts in Product, UX/ UI and Tech Architecture will focus on finding the answers to all of the questions raised throughout our journey together.",
    },
    {
      title: "Outputs",
      content:
        "Complete User Stories, Key BPMN(Business Process Model & Notation), Tech Scope, Tech Spike, Business Model.",
    },
  ]
  return <CustomHowWeDoIt data={data} />
}
const ProductDesign = () => (
  <Layout insightCategory="product-design">
    <SEO
      title="Software product design and development| Touchcore Technology Nigeria"
      description="Touchcore provides full-cycle software product development services from market research and business analysis to design, development, and launch."
    />
    <HeroSection />
    <DifferentByDesign />
    <HowWeDoIt />
    <OtherServices />
  </Layout>
)

export default ProductDesign

export function TableLikeComponent({ data }) {
  return (
    <div>
      {data.map((v, i) => (
        <div
          key={"tablike" + i}
          className="mb-16 md:mb-0 grid md:grid-cols-3 lg:grid-cols-4 gap-5 md:gap-10 md:w-10/12 mx-auto mt-8"
        >
          <h6
            data-aos="fade-right"
            data-aos-delay={200 + 50 * i}
            className="lg:col-start-2 text-pgray font-black text-2xl text-center md:text-right leading-tight"
          >
            {v.title}
          </h6>
          <p
            data-aos="fade-left"
            data-aos-delay={200 + 50 * i}
            className="text-pgray2 text-justify md:text-left md:col-span-2"
          >
            {v.content}
          </p>
        </div>
      ))}
    </div>
  )
}
